import * as React from 'react'
import AboutHeader from '../components/about_header'
import Container from '../components/container'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Button from '../components/button'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import Link from '../components/link'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const MediaResourcesPage = ({ data }) => {

  const executives = data.contentfulPage.items.filter(item => item.executiveHeadshot)

  return (
    <Layout>
      <Seo
        title='Media Resources'
        description='For our media friends - find the resources you need to rave about Roadster.'
      />
      <AboutHeader
        title='For Our Media Friends'
        subtitle='Find the resources you need to rave about Roadster.'
        isPress={true}
      />
      <div className='Section'>
        <Container size='lg'>
          <h2 className='text-xl sm:text-2xl text-center mt-0 mb-4'>
            Logo Guidelines
          </h2>
          <div className='Section-text'>
            <ul className='leading-relaxed'>
              <li>
                The Roadster trademark appears in either black or white. No other colors are permitted. The trademark has two configurations—Horizontal and Stacked.
              </li>
              <li className='mt-1'>
                The Roadster logo is precisely proportioned.
              </li>
              <li className='mt-1'>
                The logo’s shape, proportion, and orientation must not be altered in any way. Clear space must surround the logo on all sides, so that no type, design, or photographic elements encroach on the logo.
              </li>
            </ul>
          </div>
          <hr className='my-4 lg:my-5' />
          <div className='flex items-center justify-between mb-4 sm:mb-5'>
            <h2 className='sm:text-2xl sm:font-light m-0'>
              Roadster Logo
            </h2>
            <Button
              href='https://media.roadster.com/downloads/RoadsterLogos.zip'
              variant='outline'
              target='_blank'
              rel='noopener noreferrer'
              children='Download All'
            />
          </div>
          <div className='grid sm:grid-cols-2 gap-4'>
            <div>
              <StaticImage
                src='../images/press/01_roadster-logo-black.jpg'
                alt=''
                placeholder='tracedSVG'
                className='border border-darken2'
              />
              <div className='mt-3 text-gray'>
                Black Logo
              </div>
            </div>
            <div>
              <StaticImage
                src='../images/press/02_roadster-logo-white.jpg'
                alt=''
                placeholder='tracedSVG'
                className='border border-darken2'
              />
              <div className='mt-3 text-gray'>
                White Logo
              </div>
            </div>
            <div>
              <StaticImage
                src='../images/press/03_roadster-logo-black-stacked.jpg'
                alt=''
                placeholder='tracedSVG'
                className='border border-darken2'
              />
              <div className='mt-3 text-gray'>
                Black Stacked Logo
              </div>
            </div>
            <div>
              <StaticImage
                src='../images/press/04_roadster-logo-white-stacked.jpg'
                alt=''
                placeholder='tracedSVG'
                className='border border-darken2'
              />
              <div className='mt-3 text-gray'>
                White Stacked Logo
              </div>
            </div>
          </div>
          <hr className='my-4 lg:my-5' />
          <div className='flex items-center justify-between mb-4 sm:mb-5'>
            <h2 className='sm:text-2xl sm:font-light m-0 flex-1 pr-2'>
              Executive Headshots + Bios
            </h2>
            <Button
              href='https://media.roadster.com/downloads/RoadsterExecutives.zip'
              variant='outline'
              target='_blank'
              rel='noopener noreferrer'
              children='Download All'
            />
          </div>
        </Container>
        <Container size='md'>
          <div className='grid gap-4 md:gap-5'>
            {executives.map(executive =>
              <div className='flex'>
                <div className='size-1of3 sm:size-fit'>
                  <GatsbyImage
                    image={executive.executiveHeadshot.gatsbyImageData}
                    alt=''
                  />
                </div>
                <div className='flex-1 pl-2 sm:pl-3 md:pl-4'>
                  <h3 className='font-regular text-lg sm:text-xl mt-0 mb-1 sm:font-light'>
                    {executive.name}
                  </h3>
                  <p className='sm:text-base font-semi-bold mt-0 mb-2 sm:mb-3'>
                    {executive.title}
                  </p>
                  <div className='leading-relaxed text-base sm:text-md'>
                    {renderRichText(executive.bio)}
                    <ul className='list-reset mt-3'>
                      <li>
                        <b>First car:</b>
                        {' '}
                        {executive.firstCar}
                      </li>
                      <li>
                        <b>Current car:</b>
                        {' '}
                        {executive.currentCar}
                      </li>
                      <li>
                        <b>Dream car:</b>
                        {' '}
                        {executive.dreamCar}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Container>
        <Container size='lg'>
          <hr className='my-4 lg:my-5' />
          <h2 className='sm:text-2xl sm:font-light mt-0 mb-em'>
            Product Images
          </h2>
          <p className='text-base sm:text-md'>
            Please contact
            {' '}
            <Link href='mailto:media@roadster.com' variant='underline'>media@roadster.com</Link>
            {' '}
            for product images.
          </p>
        </Container>
      </div>
    </Layout>
  )
}

export default MediaResourcesPage

export const pageQuery = graphql`
  query {
    contentfulPage(url: {eq: "team"}) {
      items {
        ... on ContentfulTeamMember {
          name
          bio {
            raw
          }
          currentCar
          dreamCar
          firstCar
          executiveHeadshot {
            gatsbyImageData(placeholder: BLURRED, width: 240)
          }
          title
        }
      }
    }
  }
`
